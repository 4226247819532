<template>
	<div>
		<flickity ref="flickity" :options="flickityOptions" :class="{'flicky-container': container}" @init="onFlickityInit">
			<div class="carousel-cell" v-for="(card, i) in cards" :key="i" :class="{ 'cancel-clicks': isCarouselDragged }">
				<slot name="card" :card="card">
					<z-card
						:image="card.image ? card.image.path : null"
						:tags="FEATURE.coursesTags ? card.tags : null"
						:title="card.name"
						:description="card.description || card.short_description"
						:url="card.url || card._url"
					>

						<template slot="footer-left">
							<div v-if="card.isPath">
								Learning J.
							</div>
						</template>

						<template slot="footer-right">
							<div v-if="FEATURE.coursesStars && isLoggedIn">
								<b-icon v-if="card.isStarredByActiveUser " icon="star-fill" font-scale="1.5" @click.prevent="toggleCourseStar(card.id, 0)"/>
								<b-icon v-else icon="star" font-scale="1.5" @click.prevent="toggleCourseStar(card.id, 1)"/>
							</div>
						</template>
					</z-card>
				</slot>
			</div>
		</flickity>

		<div class="d-flex justify-content-start buttons-wrapper">
			<a href="#" class="z-icon-wrapper -medium border" @click.prevent="previous()">
				<svg-arrow-right-icon class="left-icon w-color-primary" />
			</a>
			<a href="#" class="z-icon-wrapper -medium border" @click.prevent="next()">
				<svg-arrow-right-icon class="w-color-primary" />
			</a>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	components: {
		'flickity': 						() => import('vue-flickity'),
		'svg-arrow-right-icon': () => import('@/plugins/lib/_theme/icon/arrow-right.svg?inline')
	},

	props: {
		cards: {
			type: Array,
			required: true
		},
		container: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		])
	},

	data() {
		return {
			flickityOptions: {
				prevNextButtons: false,
				cellAlign: 'left',
				draggable: true, 
				freeScroll: true
			},
			isCarouselDragged: false
		}
	},

	methods: {
		next() {
			this.$refs.flickity.next()
		},

		previous() {
			this.$refs.flickity.previous()
		},

		onFlickityInit() {
			// prevent clicking on carousel cards while dragging
			this.$refs.flickity.on('dragStart', () => this.isCarouselDragged = true)
			this.$refs.flickity.on('dragEnd', () => this.isCarouselDragged = false)

			setTimeout(function() {
				this.$refs.flickity.reloadCells()
			}.bind(this), 1500)
		}
	}
}
</script>
<style lang="scss" scoped>
	.flicky-container {
		padding: 0 1rem;
		width: calc(100vw - ((100vw - 1140px) / 2));
		max-width: 100vw;
	}

	.buttons-wrapper {
		padding: 0 1rem;
		width: 113px;

		.left-icon {
			transform: rotate(180deg);
		}

		.z-icon-wrapper {
			z-index: 1000;

			&:not(:first-of-type) {
				margin-left: 13px;
			}
		}
	}

	::v-deep {
		.flickity-page-dots {
			display: flex;
			align-items: center;
			margin-left: 120px;

			.dot {
				width: 8px;
				height: 8px;
				background: var(--a-color-blue-light, #333);
				opacity: 1;
				
				&.is-selected {
					background: var(--a-color-primary, #333);
					width: 12px;
					height: 12px;
				}
			}
		}

		/* NOte: Useful to hide messy initial state */
		.flickity-viewport {
			overflow: hidden;
		}

		.z-card {
			align-self: stretch;
		}
	}

	.cancel-clicks {
		pointer-events: none;
	}

	.carousel-cell {
		margin-right: 30px;
		min-height: 100%;
		display: flex;
	}

	@media only screen and (min-width: 576px) {
		::v-deep .z-card {
			max-width: 438px !important;
			min-width: 438px !important;
		}
	}

	@media only screen and (max-width: 575px) {
		::v-deep .z-card {
			max-width: 300px !important;
			min-width: 300px !important;
		}
	}
</style>
